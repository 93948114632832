


















import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'PasswordEntry',
  components: {
    Tablet
  },
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    },
    stage: String,
    document: {
      type: Object,
      default () {
        return {
          href: '',
          label: ''
        }
      }
    }
  },
  data () {
    return {
      lines: [],
      passwordEntry: '',
      hintsUsed: 0
    }
  },
  computed: {
    ...mapGetters([
      'stageAnswers',
      'stageHints',
      'hintsRemaining'
    ]),
    correctAnswer () {
      return (this.stageAnswers.hasOwnProperty(this.stage)) ? this.stageAnswers[this.stage] : 'What?'
    },
    thisStageHints () {
      return (this.stageHints.hasOwnProperty(this.stage)) ? this.stageHints[this.stage] : []
    },
    hintsAvailable () {
      return (this.thisStageHints.length - this.hintsUsed)
    }
  },
  methods: {
    ...mapActions([
      'loseHint',
    ]),
    show () {
      console.log('PasswordEntry.show')
      this.$refs.tablet.show()
      this.$nextTick(()=>{
        console.log('input', this.$refs.input)
        // this.$refs.input.focus()
      })
    },
    hide () {
      this.$refs.tablet.hide()
    },
    addLines (lines) {
      this.lines = [...this.lines, ...lines]
      this.$nextTick(()=>{
        this.$refs.console.scrollTop = 9999999
      })
    },
    async submitPassword () {
      if (this.passwordEntry.trim().toUpperCase() === this.correctAnswer) {
        console.log('correct')
        this.$emit('correct')
        this.hide()
        return
      }
      console.log('incorrect')
      this.addLines([
        {text: this.passwordEntry, class:'echo'},
        {text: 'Incorrect password.', class:''}
      ])
      await this.loseHint()
      if (this.hintsRemaining) {
        let hints = (this.hintsRemaining===1) ? 'hint' : 'hints';
        this.addLines([
          {text: `You have ${this.hintsRemaining} free ${hints} remaining.`}
        ])
        return
      }
      this.addLines([
        {text: 'You have no free hints remaining. Incorrect passwords, and using hints, now cost a 30 second time penalty.'}
      ])
    },
    async useHint () {
      if (!this.hintsAvailable) {
        this.addLines([
          {text: 'Use Hint', class:'echo'},
          {text: 'No more automatic hints available. Perhaps you would like to Request Assistance?', class:''}
        ])
        return
      }
      this.addLines([
        {text: 'Use Hint', class:'echo'},
        {text: this.thisStageHints[this.hintsUsed], class:''}
      ])
      this.hintsUsed++
      await this.loseHint()
      if (this.hintsRemaining) {
        let hints = (this.hintsRemaining===1) ? 'hint' : 'hints';
        this.addLines([
          {text: `You have ${this.hintsRemaining} free ${hints} remaining.`}
        ])
        return
      }
      this.addLines([
        {text: 'You have no free hints remaining. Using hints or incorrect passwords now cost a 30 second time penalty.'}
      ])
    },
    async requestAssistance () {
      this.addLines([{text: 'Request Assistance', class:'echo'}])
      await this.$store.dispatch('requestAssistance')
      this.addLines([{text: 'Assistance is on the way.', class:''}])
    },
    reopenExercise () {
      this.$emit('back')
    }
  },
  mounted () {
    this.active = this.startActive
  }
});
