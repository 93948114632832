
















import Vue from 'vue';
import Tablet from '@/components/Tablet.vue'

export default Vue.extend({
  name: 'EmailViewer',
  components: {
    Tablet
  },
  props: {
    startActive: {
      type: Boolean,
      default: false
    },
    preventHide: {
      type: Boolean,
      default: false
    },
    src: {
      type: String,
      default: '/outlook/index.php'
    },
    document: {
      type: Object,
      default () {
        return {
          href: '',
          label: ''
        }
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    show () {
      console.log('EmailViewer.show')
      this.$refs.tablet.show()
      this.$nextTick(()=>{
        console.log('input', this.$refs.input)
        // this.$refs.input.focus()
      })
    },
    hide () {
      this.emitHide()
      this.$refs.tablet.hide()
    },
    emitHide () {
      console.log('EmailViewer.emitHide')
      this.$emit('hide')
    },
  },
  mounted () {
    this.active = this.startActive
  }
});
