








import Vue from 'vue';

export default Vue.extend({
  name: 'Flair',
  props: {
    text: {
      type: String,
      default: "Find the targeted laptop!"
    },
    delay: {
      type: Number,
      default: 1
    },
    duration: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      displayed: false,
      shown:     false
    }
  },
  computed: {
    seenFindLaptopHint () {
      return this.$store.getters.seenFindLaptopHint
    }
  },
  methods: {
    show () {
      console.log('Flair.show')
      this.displayed = true
      window.setTimeout(()=>{
        this.shown = true
        window.setTimeout(this.hide, this.duration*1000)
      }, this.delay*1000)
      this.$store.dispatch('updateMyTeam', {seenFindLaptopHint: this.seenFindLaptopHint+1})
    },
    hide () {
      this.shown = false
      window.setTimeout(()=>{
        this.displayed = false
      }, 2000)
    },
    emitClick (event) {
      this.$emit('click', event)
    }
  },
  mounted () {
    console.log('Flair.mounted')
  }
});
