






















import Vue from 'vue';

export default Vue.extend({
  name: 'Fanfair',
  props: {
    subtext: {
      type: String,
      default: "Use Map To Select A New Stage"
    }
  },
  data () {
    return {
      displayed: false,
      shown:     false
    }
  },
  computed: {},
  methods: {
    show () {
      this.displayed = true
      window.setTimeout(()=>{
        this.shown = true
      }, 1000)
    },
    hide () {
      this.displayed = false
      this.shown = false
    },
    emitClick (event) {
      this.$emit('click', event)
    }
  },
  mounted () {
    console.log('Fanfair.mounted')
  }
});
